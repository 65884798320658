export const BACKEND_ROOT = process.env.REACT_APP_BACKEND_ROOT;
export const GATEWAY_EXT_ROOT = process.env.REACT_APP_GATEWAY_EXT_ROOT;

/** Paths */
export const REGISTER_PATH = '/register';
export const REGISTER_EMBED_PATH = '/embed-register';
export const CREATE_BUSINESS_PATH = '/create-business';
export const LOGIN_PATH = '/login';
export const LOGIN_EMBED_PATH = '/embed-login';
export const LOGOUT_PATH = '/logout';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const NOT_ALLOWED_PATH = '/not-allowed';

/** URL Params */
export const AUTH_TOKEN_QUERY_PARAM_NAME = 'authToken';
export const SOURCE_QUERY_PARAM_NAME = 'source';

/** Source Values */
export const SOURCE_LOGIN = 'login';
export const SOURCE_REGISTER = 'register';

/** Local Storage Keys */
export const TEMPLATE_LS_KEY = 'template';
export const SEGMENT_LS_KEY = 'segment';
export const AUTH_TOKEN_LS_KEY = 'authtoken';

/** Email Domains To Ignore For Analytics */
export const ANALYTIC_EMAILS_IGNORE_DOMAINS = [
  'yopmail.com',
  'wordstream.com',
  'localiq.com',
  'reachlocal.com',
  'example.com',
  'gannett.com',
  'test.com',
];

/** Cookie constants */
export const AUTH_COOKIE_EXPIRATION_NAME = 'localiq_session_expiration';
export const REFRESH_COOKIE_EXPIRATION_NAME = 'localiq_refresh_expiration';

/** Regular Expressions */
export const WEBSITE_URL_REGEX = /\b(\w+[.]\w+)/;
export const PHONE_NUMBER_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
export const NO_SPACES_REGEX = /^[^\s]+$/;

/** EU Country Codes */
export const EUROPEAN_UNION_COUNTRY_CODES = ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'GR', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'];

// Product constants
export const CHATBOT_SLUG = 'chat';
export const CALL_TRACKER_SLUG = 'calltracker';
export const PREMIUM_LISTINGS_SLUG = 'premium_listings';
export const SCHEDULER_SLUG = 'scheduler';

// Product tiers
export const PRO_TIER = 'pro';
export const FREE_TIER = 'free';
export const PRO_REVIEW_TIER = 'pro_review';
export const LITE_TIER = 'lite';

// Messages
export const FIRST_NAME_IS_REQUIRED_MESSAGE = 'First name is required';
export const FIRST_NAME_NO_SPACES_MESSAGE = 'First name is invalid (no spaces allowed)';
export const LAST_NAME_IS_REQUIRED_MESSAGE = 'Last name is required';
export const LAST_NAME_NO_SPACES_MESSAGE = 'Last name is invalid (no spaces allowed)';
export const ACCOUNT_BLOCKED_ERROR_MESSAGE = 'Account blocked';

// Account Types
export const ACCOUNT_TYPE_FREEMIUM = 'FREEMIUM';
export const ACCOUNT_TYPE_PREMIUM = 'PREMIUM';

// legal links
export const selfServiceTerm = 'https://localiq.com/legal/self-serve-digital-products/';
export const privacyPolicyLink = 'https://localiq.com/legal/privacy-policy';

export const SUPPORT_EMAIL = 'mysupport@localiq.com';

// domain country codes
export const AUS_DOMAIN = 'au';
export const NZ_DOMAIN = 'nz';
export const US_DOMAIN = 'com';
