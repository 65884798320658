import { AUS_DOMAIN, NZ_DOMAIN } from '../constants';

const {
  REACT_APP_CLIENT_CENTER_HOST,
  REACT_APP_CLIENT_CENTER_HOST_AUS,
  REACT_APP_CLIENT_CENTER_HOST_NZ,
} = process.env;

export const getLoginErrorMessage = (statusCode, email, failedAttempts) => {
  if (email.includes('gmail') && statusCode === 1003) {
    return 'Invalid email or password. Please try again. If you signed up using Google Sign-In, please use the same method to log in again.';
  }

  if (statusCode === 1003) {
    if (failedAttempts >= 1 && failedAttempts <= 5) {
      return 'There was an error signing you in. Please try again.';
    } else if (failedAttempts === 6) {
      return 'You have 2 login attempts remaining before your account will be locked.';
    } else if (failedAttempts === 7) {
      return 'You have 1 login attempt remaining before your account will be locked.';
    } else {

      return 'Invalid email or password. Please try again.';
    }
  }

  if (statusCode === 1005 && failedAttempts >= 8) {
    return 'Your account has been temporarily locked. Please wait for 10 minutes before attempting to log in again. If you require immediate assistance, please contact ';
  }

  return 'There was an error signing you in. Please try again.';
};

export const determineDomain = () => {
  if (window.origin.includes(NZ_DOMAIN)) {
    return REACT_APP_CLIENT_CENTER_HOST_NZ;
  }
  if (window.origin.includes(AUS_DOMAIN)) {
    return REACT_APP_CLIENT_CENTER_HOST_AUS;
  }
  return REACT_APP_CLIENT_CENTER_HOST;
};
